.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  border-radius: 5px;
  height: 90vh;
  max-height: 850px;
  max-width: 950px;
  position: relative;
  width: 90vw;

  button {
    padding: 0px;
    font-size: 20px !important;
    position: absolute;
    right: 20px;
    top: 5px;
  }
}

.iframe {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}
